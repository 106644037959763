import React, { useEffect, useState } from 'react';
import logo from './images/logo.gif';
import aboutImage from './images/about-image.webp';
import veteransBattle from './images/veteransBattle.jpg';
import honorGif from './images/honor.gif';
import veteransDayGif from './images/slogan.webp';
import dogTagGif from './images/thanks.webp';
import logoFooter from './images/logo_footer.png';
import { RainbowKitProvider, ConnectButton, getDefaultWallets } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

// Configuración de wagmi y rainbowkit
const { chains, publicClient } = configureChains(
  [mainnet],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'VeteransCoin',
  chains,
  projectId: 'f887decf6646ffdbf016fcbf6973c8b7',
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  const { address, isConnected } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Cargar scripts externos necesarios para Bootstrap y Font Awesome.
    const scriptBootstrap = document.createElement('script');
    scriptBootstrap.src = "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/js/bootstrap.bundle.min.js";
    scriptBootstrap.async = true;
    document.body.appendChild(scriptBootstrap);

    const scriptFontAwesome = document.createElement('script');
    scriptFontAwesome.src = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/js/all.min.js";
    scriptFontAwesome.async = true;
    document.body.appendChild(scriptFontAwesome);

    const linkStyles = document.createElement("link");
    linkStyles.rel = "stylesheet";
    linkStyles.href = `${process.env.PUBLIC_URL}/styles.css`;
    document.head.appendChild(linkStyles);

    return () => {
      document.head.removeChild(linkStyles);
      document.body.removeChild(scriptBootstrap);
      document.body.removeChild(scriptFontAwesome);
    };
  }, []);

  const donate = async () => {
    if (!isConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const donationAmount = ethers.utils.parseEther('0.01');

      await signer.sendTransaction({
        to: '0x0Fa32B0afFBC131e3323A0D87636049199B6C7d4',
        value: donationAmount,
      });

      alert('Thank you for your donation!');
    } catch (error) {
      console.error('Error during donation:', error);
      if (error.code === ethers.errors.INSUFFICIENT_FUNDS) {
        alert('You do not have enough funds to complete the donation. Please ensure you have sufficient ETH in your wallet to cover the donation and gas fees.');
      } else if (error.code === 4001) {
        alert('Transaction was rejected. Please approve the transaction to proceed.');
      } else {
        alert('There was an error processing your donation. Please check your wallet and try again.');
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <div className="App">
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src={logo} alt="VeteransCoin" className="logo" style={{ height: '80px' }} />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
                aria-controls="navbarNav"
                aria-expanded={isMenuOpen}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#" onClick={handleNavLinkClick}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about" onClick={handleNavLinkClick}>
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mission" onClick={handleNavLinkClick}>
                      Our Mission
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#how-to-buy" onClick={handleNavLinkClick}>
                      How to Buy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#donate" onClick={handleNavLinkClick}>
                      Donate
                    </a>
                  </li>
                  <li className="nav-item">
                    <ConnectButton />
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {/* Hero Section */}
          <header className="hero-section text-center text-white">
            <div className="hero-stars"></div> {/* Nuevo div para las estrellas */}
            <div className="container">
              <h1 className="hero-title">Honoring Valor, Supporting Heroes!</h1>
              <p className="lead">
                Join our mission to support veterans through VeteransCoin. Donate and make a difference today.
              </p>
              <div className="contract-address">
                <strong>Contract Address:</strong> 9EwM2pPWLP5W4pSBwzppcc52QzUppbMMo9f1voMq7ank
              </div>
              <a href="https://dexscreener.com/solana/9ewm2ppwlp5w4psbwzppcc52qzuppbmmo9f1vomq7ank" className="btn btn-primary mt-3">Buy Now!</a>
            </div>
          </header>

          {/* About Section */}
          <section id="about" className="about-section py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-2">
                  <img src={aboutImage} alt="Veterans support" className="img-fluid rounded section-image" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center text-center order-lg-1">
                  <h2>About VeteransCoin</h2>
                  <p>
                    VeteransCoin is dedicated to honoring the courage of all veterans who proudly HODL their meme tokens.
                    We aim to provide support through community donations, NFT projects, and blockchain-driven
                    initiatives that make an impact in the lives of those who served.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Mission Section */}
          <section id="mission" className="mission-section py-5 bg-light">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <img src={veteransDayGif} alt="Honoring All Who Served" className="img-fluid rounded section-image" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center text-center">
                  <h2>Our Mission</h2>
                  <p>
                    VeteransCoin's mission is to honor and support U.S. veterans holders by creating a community that values the sacrifices of our heroes. With every transaction, a portion goes towards helping veterans access healthcare, emotional support, and resources to reintegrate into civilian life. By holding VeteransCoin, you join a movement that makes a positive impact on the lives of veterans.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Scrolling Line - Hold with Honor */}
            <div className="ticker-container">
              <div className="ticker ticker-right">
                <div className="ticker-content">
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                </div>
                <div className="ticker-content" aria-hidden="true">
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                  <span>#Buy $VeteransCoin today with Valor!</span>
                </div>
              </div>  
            </div>

          {/* How to Buy Section - Fixed Image with Carousel Text */}
          <section id="how-to-buy" className="how-to-buy-section py-5">
            <div className="container">
              <div className="row">
                {/* Imagen fija */}
                <div className="col-lg-6 order-lg-2">
                  <img src={honorGif} alt="How to Buy" className="img-fluid rounded section-image" />
                </div>
                {/* Contenido del carrusel */}
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h2>How to buy $VDC?</h2>
                  <Carousel controls={false} indicators={true}>
                    <Carousel.Item>
                      <div className="text-center">
                        <h2 className="mb-4">Step 1: Download a Solana Wallet</h2>
                        <p>Choose a wallet like Phantom, Sollet, or Solflare and install it on your device.</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="text-center">
                        <h2 className="mb-4">Step 2: Set Up Your Wallet</h2>
                        <p>Create a new wallet and back up your recovery phrase securely.</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="text-center">
                        <h2 className="mb-4">Step 3: Purchase VeteransCoin</h2>
                        <p>Fund your wallet with SOL (Solana), then go to a Solana exchange that lists VeteransCoin. Enter the amount to buy, confirm the transaction, and your tokens will be in your wallet.</p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          
          {/* Scrolling Line - Hold with Honor */}
              <div className="ticker-container">
                <div className="ticker ticker-left">
                  <div className="ticker-content">
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                  </div>
                  <div className="ticker-content" aria-hidden="true">
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                    <span>#HOLD VeteransCoin with Honor!</span>
                  </div>
                </div>
              </div>

          {/* Crypto Veterans Section */}
          <section id="crypto-veterans" className="crypto-veterans-section py-5">
            <div className="container">
              <div className="row">
              <div className="col-lg-6">
                  <img src={veteransBattle} alt="Token Battle" className="img-fluid rounded section-image" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center text-center">
                  <h2>Veterans of the Token Battle</h2>
                  <p>Here we honor those meme coins that fought bravely and fell, and those that are still holding the line!</p>
                  <ul className="list-unstyled">
                    <li><a href="https://dogecoin.com/" target="_blank" rel="noopener noreferrer">Dogecoin - The Shiba Defender</a></li>
                    <li><a href="https://www.shibatoken.com/" target="_blank" rel="noopener noreferrer">Shiba Inu - The Meme Revolution</a></li>
                    <li><a href="https://www.pepe.com/" target="_blank" rel="noopener noreferrer">Pepe Coin - The Frog Warrior</a></li>
                    <li><a href="https://dogwifcoin.org/" target="_blank" rel="noopener noreferrer">Dogwifhat - Just a dog wif a hat</a></li>
                    <li><a href="https://bonkcoin.com/" target="_blank" rel="noopener noreferrer">Bonk - The Dog Protector</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Donation Section */}
          <section id="donate" className="donate-section py-5 bg-secondary">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-2">
                  <img src={dogTagGif} alt="Donate Now" className="img-fluid rounded section-image" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center text-center">
                  <h2>Make a Donation</h2>
                  <p>Your contributions can make a difference in the lives of our veterans holders. Connect your wallet to proceed with a donation. God bless you!</p>
                  <button className="btn btn-success donate-button" onClick={donate} disabled={!isConnected}>
                    Donate 0.01 ETH
                  </button>
                  <div id="wallet-address" className="mt-3">{isConnected && `Connected: ${address}`}</div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer */}
          <footer className="footer py-4 bg-dark text-white">
            <div className="container">
              <div className="footer-logo">
                <img src={logoFooter} alt="Veterans Affairs Seal" className="footer-logo" style={{ height: '80px' }} />
              </div>
              <div className="footer-icons">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href="https://x.com/VeteransCoinDay" target="_blank" rel="noopener noreferrer" className="text-white">
                      <i className="fab fa-twitter fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://t.me/veterans_day_coin" target="_blank" rel="noopener noreferrer" className="text-white">
                      <i className="fab fa-telegram fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://dexscreener.com/solana/9ewm2ppwlp5w4psbwzppcc52qzuppbmmo9f1vomq7ank" target="_blank" rel="noopener noreferrer" className="text-white">
                      <i className="fas fa-chart-line fa-lg"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-text">
                <p>&copy; 2024 VeteransCoin. All Rights Reserved.</p>
              </div>
              <div className="footer-text">
                <p>Disclaimer: $VDC is a meme coin created for fun with absolutely no intrinsic value or any expectation of financial return. The token for entertainment purposes only and we take zero responsibility for the value of this token.</p>
              </div>
            </div>
          </footer>

        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
